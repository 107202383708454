import {
  GET_METER_MASTER_LIST,
  GET_METER_MASTER_LIST_SUCCESS,
  GET_METER_MASTER_LIST_FAIL,
  GET_METER_MASTER_DETAIL,
  GET_METER_MASTER_DETAIL_SUCCESS,
  GET_METER_MASTER_DETAIL_FAIL,
  ADD_NEW_METER_MASTER,
  ADD_NEW_METER_MASTER_SUCCESS,
  ADD_NEW_METER_MASTER_FAIL,
  UPDATE_METER_MASTER,
  UPDATE_METER_MASTER_SUCCESS,
  UPDATE_METER_MASTER_FAIL,
  DELETE_METER_MASTER,
  DELETE_METER_MASTER_SUCCESS,
  DELETE_METER_MASTER_FAIL,
} from "./actionTypes"

export const getMeterMasterList = () => ({
  type: GET_METER_MASTER_LIST,
})

export const getMeterMasterListSuccess = meters => ({
  type: GET_METER_MASTER_LIST_SUCCESS,
  payload: meters,
})
export const getMeterMasterListFail = error => ({
  type: GET_METER_MASTER_LIST_FAIL,
  payload: error,
})

export const getMeterMasterDetail = meterId => ({
  type: GET_METER_MASTER_DETAIL,
  meterId,
})

export const getMeterMasterDetailSuccess = meterDetail => ({
  type: GET_METER_MASTER_DETAIL_SUCCESS,
  payload: meterDetail,
})

export const getMeterMasterDetailFail = error => ({
  type: GET_METER_MASTER_DETAIL_FAIL,
  payload: error,
})

export const addNewMeterMaster = (meter, history) => ({
  type: ADD_NEW_METER_MASTER,
  payload: { meter, history },
  status: true,
})

export const addNewMeterMasterSuccess = meter => ({
  type: ADD_NEW_METER_MASTER_SUCCESS,
  payload: meter,
})

export const addNewMeterMasterFail = error => ({
  type: ADD_NEW_METER_MASTER_FAIL,
  payload: error,
})

export const updateMeterMaster = (id, meter, history) => ({
  type: UPDATE_METER_MASTER,
  payload: { id, meter, history },
})

export const updateMeterMasterSuccess = meter => ({
  type: UPDATE_METER_MASTER_SUCCESS,
  payload: meter,
})

export const updateMeterMasterFail = error => ({
  type: UPDATE_METER_MASTER_FAIL,
  payload: error,
})

export const deleteMeterMaster = meter => ({
  type: DELETE_METER_MASTER,
  payload: meter,
})

export const deleteMeterMasterSuccess = meter => ({
  type: DELETE_METER_MASTER_SUCCESS,
  payload: meter,
})

export const deleteMeterMasterFail = error => ({
  type: DELETE_METER_MASTER_FAIL,
  payload: error,
})
