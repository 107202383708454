import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"

// Crypto Redux States
import {
  GET_METER_MASTER_LIST,
  GET_METER_MASTER_DETAIL,
  ADD_NEW_METER_MASTER,
  UPDATE_METER_MASTER,
  DELETE_METER_MASTER,
} from "./actionTypes"
import {
  getMeterMasterListSuccess,
  getMeterMasterListFail,
  getMeterMasterDetailSuccess,
  getMeterMasterDetailFail,
  addNewMeterMasterSuccess,
  addNewMeterMasterFail,
  updateMeterMasterSuccess,
  updateMeterMasterFail,
  deleteMeterMasterSuccess,
  deleteMeterMasterFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMeterMasterList,
  getMeterMasterDetail,
  addNewMeterMaster,
  updateMeterMaster,
  deleteMeterMaster,
} from "helpers/fakebackend_helper"

function* fetchMeterMasterList() {
  try {
    const response = yield call(getMeterMasterList)
    yield put(getMeterMasterListSuccess(response))
  } catch (error) {
    yield put(getMeterMasterListFail(error))
  }
}

function* fetchMeterMasterDetail({ meterId }) {
  try {
    const response = yield call(getMeterMasterDetail, meterId)
    yield put(getMeterMasterDetailSuccess(response))
  } catch (error) {
    yield put(getMeterMasterDetailFail(error))
  }
}

function* onAddNewMeterMaster({ payload: { meter, history } }) {
  try {
    const response = yield call(addNewMeterMaster, meter)
    if (response.status === true) {
      yield put(addNewMeterMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Meter Master Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history(`/clients/view-client/${meter.client_id}`)
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewMeterMasterFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}

function* onUpdateMeterMaster({ payload: { id, meter, history } }) {
  try {
    const response = yield call(updateMeterMaster, id, meter)
    if (response && response.status === true) {
      yield put(updateMeterMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Meter Master Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history(`/clients/view-client/${meter.client_id}`)
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateMeterMasterFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}

function* onDeleteMeterMaster({ payload: meter }) {
  try {
    const response = yield call(deleteMeterMaster, meter)
    const response2 = yield put(deleteMeterMasterSuccess(response))
    // yield put(deleteSACHSNCodeSuccess(response))
    if (response.status === true) {
      const meterMasterListResponse = yield call(getMeterMasterList)
      if(meterMasterListResponse.status === true){

        yield put(getMeterMasterListSuccess(meterMasterListResponse))
      }
    } else {
      console.error("Error in delete Meter Master:", response.error)
    }
  } catch (error) {
    yield put(deleteMeterMasterFail(error))
  }
}

function* meterMasterSaga() {
  yield takeEvery(GET_METER_MASTER_LIST, fetchMeterMasterList)
  yield takeEvery(GET_METER_MASTER_DETAIL, fetchMeterMasterDetail)
  yield takeEvery(ADD_NEW_METER_MASTER, onAddNewMeterMaster)
  yield takeEvery(UPDATE_METER_MASTER, onUpdateMeterMaster)
  yield takeEvery(DELETE_METER_MASTER, onDeleteMeterMaster)
}

export default meterMasterSaga
