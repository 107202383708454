import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./proposalListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { getProjectUnits, getProposals } from "store/actions"

const ProposalList = () => {
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  document.title = "Proposal List"
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [vendorId, setClientId] = useState(authUser ? authUser.id : "")
  const dispatch = useDispatch()
  const proposalState = state => state.proposals.proposals.proposals
  const data = useSelector(proposalState) || []

  // useEffect(() => {
  //   dispatch(getProposals())
  // }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1"> {cellProps.row.index + 1}</h5>
            </>
          )
        },
      },

      {
        Header: "Project",
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.project_name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Proposal Date",
        accessor: "formatted_proposal_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.formatted_proposal_date}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Proposal No",
        accessor: "proposal_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.proposal_no}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Goods & Services Category",
        accessor: "proposal_type",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.proposal_type}
              </h5>
            </>
          )
        },
      },

      {
        Header: "Vendor Name",
        accessor: "vendor_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.vendor_name}
              </h5>
            </>
          )
        },
      },
      
     
      {
        Header: "Proposal Amount",
        accessor: "invoice_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.invoice_amount}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Tagged Invoice",
        accessor: "invoice_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.invoice_no || "NA"}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Approved/Rejected By",
        accessor: "approved_by_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.approved_by_name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Approved/Rejected On",
        accessor: "formatted_approved_on",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.formatted_approved_on}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "proposal_status",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5
                className="font-size-14 mb-1"
                style={{
                  fontWeight: 500,
                  color:
                    cellProps.row.original.proposal_status === 0
                      ? "blue"
                      : cellProps.row.original.proposal_status === 1
                      ? "green"
                      : cellProps.row.original.proposal_status === 2
                      ? "red"
                      : cellProps.row.original.proposal_status === 3
                      ? "orange"
                      : "black",
                }}
              >
                {cellProps.row.original.proposal_status === 0
                  ? "Pending"
                  : cellProps.row.original.proposal_status === 1
                  ? "Approved"
                  : cellProps.row.original.proposal_status === 2
                  ? "Rejected"
                  : cellProps.row.original.proposal_status === 3
                  ? "Billed"
                  : "NA"}
              </h5>
            </>
          )
        },
      },
      {
        Header: "View",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {cellProps.row.original.proposal_file_path && (
                <a
                  href={cellProps.row.original.proposal_file_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-success"
                >
                  <i
                    className="mdi mdi-eye font-size-18"
                    id={`viewleaseagreementtooltip-1`}
                    style={{ color: "#3366cc" }}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`viewleaseagreementtooltip-1`}
                  >
                    View Proposal
                  </UncontrolledTooltip>
                </a>
              )}
            </div>
          )
        },
      },
      {
        Header: "Download",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {cellProps.row.original.proposal_file_path && (
                <a
                  href={cellProps.row.original.download_document}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-success"
                  download
                >
                  <i
                    className="mdi mdi-download font-size-18"
                    id={`downloadleaseagreementtooltip`}
                    style={{ color: "#3366cc" }}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`downloadleaseagreementtooltip`}
                  >
                    Download Proposal
                  </UncontrolledTooltip>
                </a>
              )}
            </div>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/view-vendor-proposal/${cellProps.row.original.proposal_id}`}
                className="text-primary "
              >
                <i
                  className="mdi mdi-chevron-right font-size-18"
                  id={`approveRejectTooltip1`}
                  style={{ color: "#3366cc" }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`approveRejectTooltip1`}
                >
                  Action
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="My Project List"
            // breadcrumbItem="My Project List"
          />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProposalList
