import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getVendorContractRenewalDetail,
  getVendorContractRenewalCountList,
} from "helpers/fakebackend_helper"

const VendorContractRenewal = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [vendorContractCountList, setVendorContractCountList] = useState([])
  const [contractDetailsForModal, setContractDetailsForModal] = useState([])
  const [vendorId, setVendorId] = useState("")

  const [modalOpen, setModalOpen] = useState(false) // Modal visibility state
  const [selectedColumn, setSelectedColumn] = useState("")

  const toggleModal = (column, vendorId) => {
    setSelectedColumn(column) // Store which column was clicked
    setVendorId(vendorId)
    setModalOpen(!modalOpen) // Toggle modal visibility
  }

  useEffect(() => {
    if (!modalOpen) {
      setSelectedColumn("")
    }
  }, [modalOpen])

  // Fetch the vendor contract renewal list when vendorId changes
  useEffect(() => {
    const fetchVendorContractList = async () => {
      try {
        const value = {
          user_id: user.user_id,
        }
        const response = await getVendorContractRenewalCountList(value)
        setVendorContractCountList(response.data)
      } catch (error) {
        console.error("Error fetching vendor contract renewal list", error)
      }
    }

    if (user) {
      fetchVendorContractList()
    }
  }, [user])

  // Fetch modal data when a column is selected
  useEffect(() => {
    const fetchModalData = async () => {
      try {
        const value = {
          vendor_id: vendorId,
          days: selectedColumn,
        }

        const response = await getVendorContractRenewalDetail(value)
        setContractDetailsForModal(response.data)
      } catch (error) {
        console.error("Error fetching vendor contract detail", error)
      }
    }

    if (selectedColumn) {
      fetchModalData()
    }
  }, [selectedColumn])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Renewals"
            breadcrumbItem="Vendor Contract Renewal Report"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4 mb-4">
                    Vendor Contract Renewal Report
                  </CardTitle> */}

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Vendor Name</th>
                          <th>Renewal Overdue</th>
                          <th>0-30 Days</th>
                          <th>31-60 Days</th>
                          <th>61-90 Days</th>
                          <th>90+ Days</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vendorContractCountList.map((report, index) => (
                          <tr key={index}>
                            <td>{report.vendor_name}</td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("overdue", report.vendor_id)}
                            >
                              {report.renewal_overdue}
                            </td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("0-30", report.vendor_id)}
                            >
                              {report.zero_to_thirty_days}
                            </td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("31-60", report.vendor_id)}
                            >
                              {report.thirtyone_to_sixty_days}
                            </td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("61-90", report.vendor_id)}
                            >
                              {report.sixtyone_to_ninety_days}
                            </td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("91+", report.vendor_id)}
                            >
                              {report.more_than_ninety_days}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* Modal for vendor contract renewal details */}
            <Modal
              isOpen={modalOpen}
              toggle={() => setModalOpen(false)}
              className="modal-lg modal-dialog-centered"
            >
              <ModalHeader toggle={() => setModalOpen(false)}>
                {selectedColumn} Details
              </ModalHeader>
              <ModalBody>
                {/* Table displaying the contract renewal data based on selected column */}
                <Table className="table">
                  <thead>
                    <tr>
                      <th>Purpose</th>
                      <th>Validity From</th>
                      <th>Validity To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(contractDetailsForModal || []).map(
                      (contract, index) => (
                        <tr key={index}>
                          <td>{contract.purpose}</td>
                          <td>{contract.validity_from}</td>
                          <td>{contract.validity_to}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => setModalOpen(false)}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default VendorContractRenewal
