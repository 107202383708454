import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  addNewMeterMaster,
  getMeterMasterDetail,
  updateMeterMaster,
} from "store/actions"
import {
  getAllActiveClientProjectUnitList,
  getAllActiveClientsList,
} from "helpers/fakebackend_helper"

const EditConsumptionMeter = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const meterState = state => state.meterMaster.meterDetail.data
  const loadingState = state => state.meterMaster.loading
  const [isEditMode, setIsEditMode] = useState(false)
  const meterProperties = createSelector(
    meterState,
    loadingState,
    (meter, loading) => {
      return {
        meter: meter,
        loading: loading,
      }
    }
  )
  const { meter, loading } = useSelector(meterProperties)
  const [client_id, setClientId] = useState()
  const [isLoading, setLoading] = useState(loading)
  const [meterId, setMeterId] = useState()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: isEditMode
      ? {
          client_id: (meter && meter.client_id) || "",
          client_project_unit_id: (meter && meter.client_project_unit_id) || "",
          type: (meter && meter.type) || "",
          meter_no: (meter && meter.meter_no) || "",
          is_active: Boolean(meter && meter.is_active),
        }
      : {
          client_id: "",
          client_project_unit_id: "",
          meter_no: "",
          type: "",

          is_active: false,
        },
    validationSchema: Yup.object({
      is_active: Yup.boolean(),
            meter_no: Yup.string().required("Meter No. is required")
      
    }),

    onSubmit: async values => {
      if (isEditMode === true) {
        dispatch(updateMeterMaster(meterId, values, props.router.navigate))
      } else if (isEditMode === false) {
        dispatch(addNewMeterMaster(values, props.router.navigate))
      }
    },
  })

  const vendorData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const meterId = url.pathname.split("/edit-consumption-meter/")[1]
      setMeterId(meterId)

      dispatch(getMeterMasterDetail(meterId))
    } catch (error) {
      console.error(error)
    }
  }

  const [clientList, setClientList] = useState()
  const [clientProjectUnitList, setClientProjectUnitList] = useState()

  const clientListData = async () => {
    try {
      const getListClient = await getAllActiveClientsList()
      setClientList(getListClient.data)
    } catch (error) {
      console.error(error)
    }
  }
  const clientProjectUnitListData = async () => {
    try {
      const getListClientProjectUnit = await getAllActiveClientProjectUnitList(
        validation.values.client_id
      )
      setClientProjectUnitList(getListClientProjectUnit.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    clientListData()
  }, [])

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      vendorData()
    }
  }, [dispatch])

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    }
  }, [location.pathname])

  useEffect(() => {
    if (validation.values.client_id) {
      clientProjectUnitListData()
    }
  }, [validation.values.client_id])
  return (
    <div className="page-content">
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <>
          <Row>
            <Col>
              <div className="d-flex gap-2 mb-3">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey", marginRight: "15px" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>

                <span
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  {" "}
                  {isEditMode ? "Edit Meter Master" : "New Meter Master"}{" "}
                </span>
              </div>
            </Col>
            <Col>
              <Breadcrumbs title="Settings / Master configuration / Meter Master" />
            </Col>
          </Row>
          <div className="mt-3"></div>
          <Card>
            <CardBody>
              <Form
                autoComplete="off"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-client_id">Client*</Label>
                      <Input
                        type="select"
                        name="client_id"
                        className="form-control"
                        id="formrow-client_id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.client_id || ""}
                        disabled
                        invalid={
                          validation.touched.client_id &&
                          validation.errors.client_id
                            ? true
                            : false
                        }
                      >
                        <option value="">Select Client</option>
                        {clientList &&
                          clientList.map(client => (
                            <option key={client.id} value={client.id}>
                              {client.customer_name}
                            </option>
                          ))}
                      </Input>
                      {validation.touched.client_id &&
                      validation.errors.client_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.client_id}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-clientProjectUnit">
                        Client Project Unit*
                      </Label>
                      <Input
                        type="select"
                        name="client_project_unit_id"
                        className="form-control"
                        id="formrow-clientProjectUnit"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        disabled
                        value={validation.values.client_project_unit_id || ""}
                        invalid={
                          validation.touched.client_project_unit_id &&
                          validation.errors.client_project_unit_id
                            ? true
                            : false
                        }
                      >
                        <option value="">Select Client Project Unit</option>
                        {clientProjectUnitList &&
                          clientProjectUnitList.map(unit => (
                            <option key={unit.id} value={unit.id}>
                              {unit.unit_code}
                            </option>
                          ))}
                      </Input>
                      {validation.touched.client_project_unit_id &&
                      validation.errors.client_project_unit_id ? (
                        <FormFeedback type="invalid">
                          {validation.errors.client_project_unit_id}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-clientProjectUnit">
                        Consumption type*
                      </Label>
                      <Input
                        type="select"
                        name="type"
                        className="form-control"
                        id="formrow-clientProjectUnit"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        disabled
                        value={validation.values.type || ""}
                        invalid={
                          validation.touched.type && validation.errors.type
                            ? true
                            : false
                        }
                      >
                        <option value="">Select Consumption Type</option>
                        <option value="Electricity">Electricity</option>
                        <option value="Water">Water</option>
                        <option value="Gas">Gas</option>
                        <option value="DG">DG</option>
                      </Input>
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-unit-Input">Meter No.*</Label>
                      <Input
                        type="text"
                        name="meter_no"
                        className="form-control"
                        id="formrow-unit-Input"
                        placeholder="Enter Meter No"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.meter_no || ""}
                        invalid={
                          validation.touched.meter_no &&
                          validation.errors.meter_no
                            ? true
                            : false
                        }
                      />
                      {validation.touched.meter_no &&
                      validation.errors.meter_no ? (
                        <FormFeedback type="invalid">
                          {validation.errors.meter_no}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-2"></div>
                    <div>
                      <Label htmlFor="formrow-ispaid-Input">Is Active</Label>
                    </div>

                    <Col lg="12">
                      <div className="form-check form-check-inline">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="is_active"
                          name="is_active"
                          onChange={e => {
                            validation.setFieldValue(
                              "is_active",
                              e.target.checked
                            )
                          }}
                          checked={Boolean(validation?.values?.is_active)}
                        />
                        <Label className="form-check-Label" htmlFor="is-Active">
                          <h6>Yes</h6>
                        </Label>
                      </div>
                      {validation.touched.is_active &&
                        validation.errors.is_active && (
                          <FormFeedback type="invalid">
                            {validation.errors.is_active}
                          </FormFeedback>
                        )}
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3 text-end">
                      <button type="submit" className="btn btn-primary w-md">
                        {isEditMode ? "Edit" : "Submit"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  )
}

export default withRouter(EditConsumptionMeter)

EditConsumptionMeter.propTypes = {
  history: PropTypes.object,
}
