import {
  GET_METER_MASTER_LIST_SUCCESS,
  GET_METER_MASTER_LIST_FAIL,
  GET_METER_MASTER_DETAIL_SUCCESS,
  GET_METER_MASTER_DETAIL_FAIL,
  ADD_NEW_METER_MASTER_SUCCESS,
  ADD_NEW_METER_MASTER_FAIL,
  UPDATE_METER_MASTER_SUCCESS,
  UPDATE_METER_MASTER_FAIL,
  DELETE_METER_MASTER_SUCCESS,
  DELETE_METER_MASTER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  meter: [],
  addMeter: [],
  meterDetail: {},
  error: {},
  loading: true,
}

const meterMaster = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_METER_MASTER_LIST_SUCCESS:
      return {
        ...state,
        meter: action.payload,
        loading: false,
      }

    case GET_METER_MASTER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_METER_MASTER_DETAIL_SUCCESS:
      return {
        ...state,
        meterDetail: action.payload,
        loading: false,
      }

    case GET_METER_MASTER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_METER_MASTER_SUCCESS:
      return {
        ...state,
        addMeter: [...state.addMeter, action.payload],
      }

    case ADD_NEW_METER_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_METER_MASTER_SUCCESS:
      return {
        ...state,
        addMeter: state.addMeter.map(meter =>
          meter.id === action.payload.id ? { ...meter, ...action.payload } : meter
        ),
      }

    case UPDATE_METER_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_METER_MASTER_SUCCESS:
      return {
        ...state,
        meter: state.meter.data.filter(
          meter => meter.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_METER_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default meterMaster
