import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getProjectComplianceDetail,
  getProjectComplianceCountList,
} from "helpers/fakebackend_helper"

const ComplianceCertificateList = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [projectComplianceCountList, setProjectComplianceCountList] = useState(
    []
  )
  const [projectComplianceForModel, setProjectComplianceForModel] = useState([])
  const [projectId, setProjectId] = useState("")

  const [modalOpen, setModalOpen] = useState(false) // Modal visibility state
  const [selectedColumn, setSelectedColumn] = useState("")

  const toggleModal = (column,projectId) => {
    setSelectedColumn(column) // Store which column was clicked
    setProjectId(projectId) 
    setModalOpen(!modalOpen) // Toggle modal visibility
  }

  useEffect(() => {
    if (!modalOpen) {
      setSelectedColumn("")
    }
  }, [modalOpen])

  // Fetch the project compliance list when projectId changes
  useEffect(() => {
    const projectComplianceList = async () => {
      try {
        const value = {
          user_id: user.user_id,
        }
        const response = await getProjectComplianceCountList(value)
        setProjectComplianceCountList(response.data)
      } catch (error) {
        console.error("Error fetching project compliance list", error)
      }
    }

    if (user) {
      projectComplianceList()
    }
  }, [user])

 

  // Fetch modal data when a column is selected
  useEffect(() => {
    const fetchModalData = async () => {
      try {
        const value = {
          project_id: projectId,
          days: selectedColumn,
        }

        const response = await getProjectComplianceDetail(value)
        setProjectComplianceForModel(response.data)
      } catch (error) {
        console.error("Error fetching project detail", error)
      }
    }

    if (selectedColumn) {
      fetchModalData()
    }
  }, [selectedColumn])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Renewals"
            breadcrumbItem="Compliance Certificate Report"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4 mb-4">
                    Project Compliance Certificate Report
                  </CardTitle> */}
                 

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Renewal Overdue</th>
                          <th>0-30 Days</th>
                          <th>31-60 Days</th>
                          <th>61-90 Days</th>
                          <th>90+ Days</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectComplianceCountList.map((report, index) => (
                          <tr key={index}>
                            <td>{report.project_name}</td>
                            <td className="clickable-column"
                              onClick={() => toggleModal("overdue",report.project_id)}>{report.renewal_overdue}</td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("0-30",report.project_id)}
                            >
                              {report.zero_to_thirty_days}
                            </td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("31-60",report.project_id)}
                            >
                              {report.thirtyone_to_sixty_days}
                            </td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("61-90",report.project_id)}
                            >
                              {report.sixtyone_to_ninety_days}
                            </td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("90+",report.project_id)}
                            >
                              {report.more_than_ninety_days}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* Modal for overdue categories */}
            <Modal
              isOpen={modalOpen}
              toggle={() => setModalOpen(false)}
              className="modal-lg modal-dialog-centered"
            >
              <ModalHeader toggle={() => setModalOpen(false)}>
                {selectedColumn} Details
              </ModalHeader>
              <ModalBody>
                {/* Table displaying the overdue data based on selected column */}
                <Table className="table">
                  <thead>
                    <tr>
                      <th>Compliance</th>
                      <th>From</th>
                      <th>To</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    {(projectComplianceForModel || []).map(
                      (comp, index) => (
                        <tr key={index}>
                          <td>{comp.compliance_title}</td>
                          <td>{comp.from_date}</td>
                          <td>{comp.to_date}</td>
                          
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </ModalBody>
              <ModalFooter>
                {/* <Button color="secondary" onClick={() => setModalOpen(false)}>
                  Close
                </Button> */}
              </ModalFooter>
            </Modal>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ComplianceCertificateList
