import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  FormFeedback,
} from "reactstrap"
import {
  addNewBill,
  editBill,
  getApplicableRateList,
  getAssignedProjectUnitListInBilling,
  getBillingClientDetail,
  getBillingClientList,
  getBillingCompanyDetail,
  getBillingCompanyList,
  getBillingData,
  getCities,
  getCountries,
  getInvoiceNumber,
  getInvoiceTypeMasterList,
  getPlaceOfSupply,
  getProjectDetailInBilling,
  getProjectListInBilling,
  getProjectUnitDetailInBilling,
  getSacHsnCodeBilling,
  getStates,
  getSupplyList,
} from "store/actions"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import { createSelector } from "reselect"
import LargeModal from "./detailedSummaryModal"
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { getClientProjectUnitConsumptionMeterListInBilling, getConsumptionForBilling } from "helpers/fakebackend_helper"

const EditBill = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const dispatch = useDispatch()
  const path = useLocation()
  const navigate = useNavigate()
  const [clientId, setClientId] = useState()
  const [unitId, setUnitId] = useState()
  const [billingId, setBillingId] = useState()

  const BillingState = state => state.billing

  const AllBillingProperties = createSelector(
    BillingState,

    billing => ({
      clientList: billing.clientList.data,
      clientDetail: billing.clientDetail.client,
      engagementService: billing.serviceEngagement.project_unit,
      billingCompanyList: billing.companyList.companies,
      billingCompanyDetail: billing.companyDetail.company,
      typeOfSupplyList: billing.supplyList.data,
      placeOfSupplyList: billing.placeOfSupply.data,
      applicableRateList: billing.applicableRateList.data,
      invoiceTypeMasterList: billing.invoiceTypeMasterList.data,
      invoiceNumber: billing.invoiceNumber.invoice_number,
      billingData: billing.billingData.billing,
      sacHsnCode: billing.codesBilling.data,
      assignedProjectUnitList: billing.assignedProjectUnit.project_units,
      projectList: billing.projectList.data,
      projectDetail: billing.projectDetail.data,
      projectUnitDetail: billing.projectUnitDetail.project_unit,
      billingCompanylocation: billing.companyDetail.location,
    })
  )

  const {
    clientList,
    clientDetail,
    engagementService,
    billingCompanyList,
    billingCompanyDetail,
    typeOfSupplyList,
    placeOfSupplyList,
    applicableRateList,
    invoiceTypeMasterList,
    invoiceNumber,
    billingData,
    sacHsnCode,
    assignedProjectUnitList,
    projectList,
    projectDetail,
    projectUnitDetail,
    billingCompanylocation,
  } = useSelector(AllBillingProperties)

  useEffect(() => {
    // dispatch(getBillingClientList())

    dispatch(getBillingCompanyList())
    dispatch(getSupplyList())
    dispatch(getPlaceOfSupply())
    dispatch(getApplicableRateList())
    dispatch(getInvoiceTypeMasterList())
    dispatch(getSacHsnCodeBilling())
  }, [dispatch])

  useEffect(() => {
    if (user) {
      dispatch(getProjectListInBilling(user.user_id))
    }
  }, [user])

  useEffect(() => {
    const pathName = path.pathname
    const billingId = pathName.split("/edit-bill/")[1]
    setBillingId(billingId)
    dispatch(getBillingData(billingId))
  }, [dispatch, path])

  //FORMIK VALIDATION
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      // client: (billingData && billingData.customer_id) || "",

      billing_id: (billingData && billingData.billing_id) || "",
      project_id: (billingData && billingData.project_id) || "",
      location_id: (billingData && billingData.location_id) || "",
      customer_id: (billingData && billingData.customer_id) || "",
      company_id: (billingData && billingData.company_id) || "",
      client_project_unit_id:
        (billingData && billingData.client_project_unit_id) || "",
      // billing_mode: (billingData && billingData.billing_mode) || "",
      // commencement_date: (billingData && billingData.commencement_date) || "",
      gst_applicable: (billingData && billingData.gst_applicable) || "",
      bill_date: (billingData && billingData.bill_date) || "",
      bill_due_date: (billingData && billingData.bill_due_date) || "",
      place_of_supply: (billingData && billingData.place_of_supply) || "",
      type_of_supply: (billingData && billingData.type_of_supply) || "",
      gst_rate: (billingData && billingData.gst_rate) || "",
      // discount: (billingData && billingData.discount) || "",
      tax_amount: (billingData && billingData.tax_amount) || "",
      bill_no: (billingData && billingData.bill_no) || "",
      billing_components:
      
         [],
      billing_components_show:
        billingData?.billing_components?.map(component => ({
          sac_hsn: component?.sac_hsm_code || "",
          invoice_component: component?.invoice_type,
        })) || [],
      //additional field
      delivery_note: (billingData && billingData.delivery_note) || "",
      delivery_note_date: (billingData && billingData.delivery_note_date) || "",
      reference_no: (billingData && billingData.reference_no) || "",
      reference_date: (billingData && billingData.reference_date) || "",
      other_references: (billingData && billingData.other_references) || "",
      buyers_order_no: (billingData && billingData.buyers_order_no) || "",
      dated: (billingData && billingData.dated) || "",
      dispatch_doc_no: (billingData && billingData.dispatch_doc_no) || "",
      dispatched_through: (billingData && billingData.dispatched_through) || "",
      destination: (billingData && billingData.destination) || "",
      remarks: (billingData && billingData.remarks) || "",
      //
      billing_period_from:
        (billingData && billingData.billing_period_from) || "",
      billing_period_to: (billingData && billingData.billing_period_to) || "",
      ledger_text: (billingData && billingData.ledger_text) || "",
    },
    validationSchema: Yup.object().shape({
      customer_id: Yup.number().required("Please select Customer"),
      company_id: Yup.number().required("Please select Company"),
      // gst_applicable: Yup.string().required("Please select Gst Applicable"),
      bill_no: Yup.string(),

      place_of_supply: Yup.number().required("Please select Place of Supply"),
      type_of_supply: Yup.number().required("Please select Type of Supply"),
      gst_rate: Yup.number(),
      tax_amount: Yup.number(),
      bill_date: Yup.string().required("Invoice date is required"),
      bill_due_date: Yup.string().when("bill_date", (bill_date, schema) => {
        return bill_date
          ? schema
              .test(
                "is-after-start",
                "Due date must be after Invoice date",
                function (value) {
                  const { bill_date } = this.parent
                  if (!bill_date || !value) return true // Allow null values
                  return new Date(value) > new Date(bill_date)
                }
              )
              .required("Due date is required when Invoice date is provided")
          : schema
      }),
    }),

    onSubmit: async values => {
      dispatch(editBill(billingId, props.router.navigate, values))
    },
  })

  useEffect(() => {
    if (validation.values.customer_id) {
      const value = {
        project_id: validation.values.project_id,
        client_id: validation.values.customer_id,
      }
      dispatch(getAssignedProjectUnitListInBilling(value))
    }
  }, [validation.values.customer_id])

  useEffect(() => {
    if (validation.values.project_id) {
      dispatch(getBillingClientList(validation.values.project_id))
      dispatch(getProjectDetailInBilling(validation.values.project_id))
    }
  }, [validation.values.project_id])

  useEffect(() => {
    if (validation.values.customer_id) {
      const clientId = validation.values.customer_id
      setClientId(clientId)
      dispatch(getBillingClientDetail(clientId))
      // validation.setFieldValue("customer_id", clientId)
    }
  }, [validation.values.customer_id])

  useEffect(() => {
    if (validation.values.client_project_unit_id) {
      dispatch(
        getBillingCompanyDetail(validation.values.client_project_unit_id)
      )
    }
  }, [validation.values.client_project_unit_id])

  useEffect(() => {
  
    if (validation.values.client_project_unit_id) {
      dispatch(
        getProjectUnitDetailInBilling(validation.values.client_project_unit_id)
      )
    }
  }, [validation.values.client_project_unit_id])

  // useEffect(() => {
  //   if (invoiceNumber) {
  //     validation.setFieldValue("bill_no", invoiceNumber)
  //   }
  // }, [invoiceNumber])

  const [rows, setRows] = useState([{ id: 1 }])

  useEffect(() => {
    if (
      validation &&
      validation.values &&
      validation.values.billing_components
    ) {
      // Update rows state based on the length of billing_components array
      setRows(
        [...Array(validation.values.billing_components.length)].map(
          (_, index) => ({ id: index })
        )
      )
    }
  }, [validation.values.billing_components])

  const [hidden, setHidden] = useState(false)

  const toggleHidden = () => {
    setHidden(!hidden)
  }

  const removeRow = id => {
    if (rows.length === 1) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You cannot delete the last row!",
      })
    } else {
      const updatedRows = rows.filter(row => row.id !== id)
      // Remove data of the row from billing_components
      const updatedBillingComponents =
        validation.values.billing_components.filter((_, index) => index !== id)
      // Update validation.values.billing_components with the updated array
      validation.setFieldValue("billing_components", updatedBillingComponents)
      setRows(updatedRows)
    }
  }
  const [billingFrequency, setBillingFrequency] = useState("")
  const [billingDates, setBillingDates] = useState([])
  const [commencementDate, setCommencementDate] = useState("")

  const handleBillingFrequencyChange = e => {
    const selectedFrequency = e.target.value
    setBillingFrequency(selectedFrequency)
    calculateBillingDates(selectedFrequency, commencementDate)
  }

  const handleCommencementDateChange = e => {
    const selectedCommencementDate = e.target.value
    setCommencementDate(selectedCommencementDate)
    calculateBillingDates(billingFrequency, selectedCommencementDate)
  }

  const calculateBillingDates = (frequency, commencementDate) => {
    const startDate = new Date(commencementDate)
    const calculatedBillingDates = []

    let interval
    let iterations
    switch (frequency) {
      case "Monthly":
        interval = 30
        iterations = 12
        break
      case "Quaterly":
        interval = 90
        iterations = 4
        break
      case "Half-Yearly":
        interval = 180
        iterations = 2
        break
      case "Yearly":
        interval = 365
        iterations = 1
        break
      default:
        interval = 0
        iterations = 0
        break
    }

    if (isNaN(startDate.getTime())) {
      console.error("Invalid commencement date")
      return
    }
    const formatDate = date => {
      const day = String(date.getDate()).padStart(2, "0")
      const month = String(date.getMonth() + 1).padStart(2, "0")
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    }

    for (let i = 1; i <= iterations; i++) {
      const newDate = new Date(
        startDate.getTime() + interval * i * 24 * 60 * 60 * 1000
      )
      calculatedBillingDates.push(formatDate(newDate))
    }
    setBillingDates(calculatedBillingDates)
  }

  const [modal_large, setmodal_large] = useState(false)
  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }

  const [consumption, setConsumption] = useState({ Electricity: 0, Gas: 0, Water: 0, DG:0 });

  useEffect(() => {
    

    if (billingData) {
      const billingComponents =
        billingData.billing_components.map(component => {
          let amt_per_unit = component?.amt_per_unit || 0;
          let rate_per_unit = component?.rate_per_unit || 0;
          let unit = component?.unit || 0;
          let ConsumptionType = component?.consumption_type;
          // let Water =consumption.Water
          // let Gas =consumption.Gas
          // let Electricity =consumption.Electricity
          // Check the consumption type and modify amt_per_unit, rate, and unit if necessary
          // if (ConsumptionType === 'Water') {
          //   unit = consumption.Water || 1;
          // } else if (ConsumptionType === 'Gas') {
          //   unit = consumption.Gas || 1;
          // } else if (ConsumptionType === 'Electricity') {
          //   unit = consumption.Electricity || 1;
          // }

          return {
            invoice_type_id: component?.invoice_type_id,
            // invoice_type: component?.invoice_type?.invoice_type || "",
            amt_per_unit: amt_per_unit,
            consumption_type: component?.consumption_type,
            // unit: ConsumptionType === 'Water' ? Water : ConsumptionType === 'Gas' ? Gas :  ConsumptionType === 'Electricity : 1' ? Electricity : 1 ,
            unit: unit,
            rate_per_unit: rate_per_unit,
            sac_hsm_id: component?.sac_hsm_id || "",
            // sac_hsn: component?.sac_hsn?.sac_hsm_code || "",
            description: component?.description || "",
            
            gst_percentage: component?.gst_percentage || "",
            discount: 0,
            check: component?.check || 0,
          };
        });

      validation.setFieldValue("billing_components", billingComponents);
    }
  }, [ billingData]);
  
  
  useEffect(() => {
    if (billingData) {
      const billingComponents =
        billingData.billing_components.map(component => {
          let amt_per_unit = component?.amt_per_unit || 0;
          let rate_per_unit = component?.rate_per_unit || 0;
          let unit = component?.unit || 1;
          let ConsumptionType = component?.consumption_type;
          let Water =consumption.Water
          let Gas =consumption.Gas
          let Electricity =consumption.Electricity
          let DG =consumption.DG
        

          return {
            invoice_type_id: component?.invoice_type_id,
            // invoice_type: component?.invoice_type?.invoice_type || "",
            amt_per_unit: amt_per_unit,
            consumption_type: component?.consumption_type,
            unit: ConsumptionType === 'Water' ? Water : ConsumptionType === 'Gas' ? Gas :  ConsumptionType === 'Electricity' ? Electricity : ConsumptionType === 'DG' ? DG : 1 ,
            // unit: unit,
            rate_per_unit: rate_per_unit,
            sac_hsm_id: component?.sac_hsm_id || "",
            // sac_hsn: component?.sac_hsn?.sac_hsm_code || "",
            description: component?.description || "",
            
            gst_percentage: component?.gst_percentage || "",
            discount: 0,
            check: component?.check || 0,
          };
        });
      validation.setFieldValue("billing_components", billingComponents);
    }
  }, [ consumption]);

  const handleGetConsumption = async () => {
    const value = {
      billing_period_from: validation.values.billing_period_from,
      billing_period_to: validation.values.billing_period_to,
      client_project_unit_id: validation.values.client_project_unit_id,
      electricity_meter_id: validation.values.electricity_meter,
      dg_meter_id: validation.values.dg_meter,
      water_meter_id: validation.values.water_meter,
      gas_meter_id: validation.values.gas_meter,
    }
    const ConsumptionData = await getConsumptionForBilling(value)

    if (ConsumptionData.status === true) {
      setConsumption(ConsumptionData.total_consumption)
    }
  }

    const [consumptionMeterList, setConsumptionMeterList] = useState([])
    const getClientProjectUnitConsumptionMeter = async () => {
      const ConsumptionMeterData =
        await getClientProjectUnitConsumptionMeterListInBilling(
          validation.values.client_project_unit_id
        )
      setConsumptionMeterList(ConsumptionMeterData.data)
    }
  
    useEffect(() => {
      if (validation.values.client_project_unit_id) {
        getClientProjectUnitConsumptionMeter()
      }
    }, [validation.values.client_project_unit_id])

  return (
    <React.Fragment>
      {/* <LargeModal isOpen={modalLarge} toggle={toggleModalLarge} />*/}
      <LargeModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        data={validation.values}
        applicableRateList={applicableRateList}
        billingCompanyDetail={billingCompanyDetail}
        placeOfSupplyList={placeOfSupplyList}
        invoiceTypeMasterList={invoiceTypeMasterList}
      />
      <div className="page-content ">
        <Container fluid={true}>
          <Form>
            <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
              <div className="d-flex gap-2 ">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>
                Edit Bill
              </div>
            </CardTitle>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="mb-3">Client Details</CardTitle> */}
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.project_id &&
                              validation.errors.project_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="project_id"
                            value={validation.values.project_id || ""}
                          >
                            <option value="">Select Project</option>
                            {(projectList || []).map(project => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project_id &&
                            validation.errors.project_id && (
                              <div className="invalid-feedback">
                                {validation.errors.project_id}
                              </div>
                            )}
                        </div>
                        {validation.values.project_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Project Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_name">
                                        Project Name
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="property_type">
                                        Property Type
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.property_type}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_use">
                                        Project Use
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_use}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_area_sqft">
                                        Project Area
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_area_sqft}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_type">
                                        Project Type
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_type}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="total_floors">
                                        Total Floors
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.total_floors}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="location">Location</Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.location}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_manager_id">
                                        Project Manager
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.full_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="parking_available">
                                        Parking Available
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                        projectDetail.parking_available === 1
                                          ? "Yes"
                                          : "No"}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="parking_type">
                                        Parking Type
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.parking_type}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="contract_terms_start_date">
                                        Contract Start Date
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.contract_terms_start_date}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="contract_terms_end_date">
                                        Contract End Date
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.contract_terms_end_date}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.customer_id &&
                              validation.errors.customer_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="customer_id"
                            value={validation.values.customer_id || ""}
                          >
                            <option value="">Select Client</option>
                            {(clientList || []).map(client => (
                              <option key={client.id} value={client.id}>
                                {client.customer_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.customer_id &&
                            validation.errors.customer_id && (
                              <div className="invalid-feedback">
                                {validation.errors.customer_id}
                              </div>
                            )}
                        </div>
                        {validation.values.customer_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Client Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="customer_code">
                                        Customer Code
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.customer_code}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="customer_name">
                                        Customer Name
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.customer_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="email_address">
                                        Email Address
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.email_address}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="mobile_no">
                                        Mobile Number
                                      </Label>
                                      <p>
                                        {clientDetail && clientDetail.mobile_no}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="address">Address</Label>
                                      <p>
                                        {clientDetail && clientDetail.address}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pincode">Pincode</Label>
                                      <p>
                                        {clientDetail && clientDetail.pincode}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="country_name">
                                        Country
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.country_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="state_name">State</Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.state_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="city_name">City</Label>
                                      <p>
                                        {clientDetail && clientDetail.city_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="gst_no">GST Number</Label>
                                      <p>
                                        {clientDetail && clientDetail.gst_no}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pan_no">PAN Number</Label>
                                      <p>
                                        {clientDetail && clientDetail.pan_no}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.client_project_unit_id &&
                              validation.errors.client_project_unit_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="client_project_unit_id"
                            value={validation.values.client_project_unit_id}
                          >
                            <option value="">Select Project Unit</option>
                            {(assignedProjectUnitList || []).map(company => (
                              <option key={company.id} value={company.id}>
                                {company.unit_code}
                              </option>
                            ))}
                          </select>
                          {validation.touched.client_project_unit_id &&
                            validation.errors.client_project_unit_id && (
                              <div className="invalid-feedback">
                                {validation.errors.client_project_unit_id}
                              </div>
                            )}
                        </div>
                        {validation.values.client_project_unit_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Project Unit Detail (
                                  {projectUnitDetail &&
                                    projectUnitDetail.unit_code}
                                  )
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_name">
                                        Project Name
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.project_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="brand_name">Brand</Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.brand_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="revenue_share">
                                        Revenue Share
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          (projectUnitDetail.revenue_share === 1
                                            ? "Monthly"
                                            : "Annually")}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="revenue_share_percentage">
                                        Revenue Share(%)
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.revenue_share_percentage}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="rent_payment_schedule">
                                        Rent Payment Schedule
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          (projectUnitDetail.rent_payment_schedule ===
                                          1
                                            ? "Monthly"
                                            : projectUnitDetail.rent_payment_schedule ===
                                              2
                                            ? "Quarterly"
                                            : projectUnitDetail.rent_payment_schedule ===
                                              3
                                            ? "Half Yearly"
                                            : "Yearly")}{" "}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="customer_lock_in_period">
                                        Customer Lock-In Period(Months)
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.customer_lock_in_period}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="notice_period">
                                        Notice Period(Months)
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.notice_period}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="security_deposit_for_rent">
                                        Security Deposit for Rent
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.security_deposit_for_rent}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="security_deposit_for_electricity">
                                        Security Deposit for Electricity
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.security_deposit_for_electricity}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="security_deposit_for_maintenance">
                                        Security Deposit for Maintenance
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.security_deposit_for_maintenance}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="agreement_start_date">
                                        Agreement Start Date
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.agreement_start_date}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="agreement_end_date">
                                        Agreement End Date
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.agreement_end_date}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        {validation.values.client_project_unit_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Company Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="name">Company Name</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="address_line_1">
                                        Address
                                      </Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.address_line_1}{" "}
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.address_line_2}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pin_code">Pincode</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.pin_code}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="country_name">
                                        Country
                                      </Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.country_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="state_name">State</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.state_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="city_name">City</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.city_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                      <Col lg={4}>
                        {validation.values.client_project_unit_id && (
                          <Card>
                            <CardBody
                              style={{
                                backgroundColor: "#EEEEEE",
                                borderRadius: "15px",
                              }}
                            >
                              <CardTitle className="mb-3">
                                Billing Company's Location Detail
                              </CardTitle>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Address</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.location_title}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Country</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.country_name}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">State</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.state_name}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">City</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.city_name}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">GST No.</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.gst_no}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="ledger_text">Ledger Text*</Label>
                          <Input
                            type="select"
                            name="ledger_text"
                            className={`form-select ${
                              validation.touched.ledger_text &&
                              validation.errors.ledger_text
                                ? "is-invalid"
                                : ""
                            }`}
                            id="ledger_text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ledger_text || ""}
                          >
                            <option value="">Select Ledger Text</option>
                            <option value="Rent Charges">Rent Charges</option>
                            <option value="License Fee">License Fee</option>
                            <option value="Lease Rent Charges">
                              Lease Rent Charges
                            </option>
                            <option value="Infrastructure Fee">
                              Infrastructure Fee
                            </option>
                            <option value="CAM Charges">CAM Charges</option>
                            <option value="Maintenance Charges">
                              Maintenance Charges
                            </option>
                            <option value="Electricity Charges">
                              Electricity Charges
                            </option>
                            <option value="Support Service to Electricity Charges">
                              Support Service to Electricity Charges
                            </option>
                            <option value="Gas Charges">Gas Charges</option>
                            <option value="DG Charges">DG Charges</option>
                            <option value="Water Charges">Water Charges</option>
                            <option value="Banquet Charges">
                              Banquet Charges
                            </option>
                            <option value="Parking Charges">
                              Parking Charges
                            </option>
                            <option value="Event Spaces Charges">
                              Event Spaces Charges
                            </option>
                            <option value="Branding Space Charges">
                              Branding Space Charges
                            </option>
                            <option value="Kiosks Charges">
                              Kiosks Charges
                            </option>
                          </Input>
                          {validation.touched.ledger_text &&
                            validation.errors.ledger_text && (
                              <div className="invalid-feedback">
                                {validation.errors.ledger_text}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
           <Row>
                         <Col>
                           <Card>
                             <CardBody>
                               <Row>
                                 <Col lg={4}>
                                   <div className="mb-3">
                                     <Label htmlFor="billing_period_from">
                                       Billing Period Start Date
                                     </Label>
                                     <Input
                                       name="billing_period_from"
                                       type="date"
                                       className={`form-control ${
                                         validation.touched.billing_period_from &&
                                         validation.errors.billing_period_from
                                           ? "is-invalid"
                                           : ""
                                       }`}
                                       id="billing_period_from"
                                       placeholder="Enter Start Date"
                                       onChange={validation.handleChange}
                                       onBlur={validation.handleBlur}
                                       value={validation.values.billing_period_from || ""}
                                     />
                                     {validation.touched.billing_period_from &&
                                     validation.errors.billing_period_from ? (
                                       <FormFeedback type="invalid">
                                         {validation.errors.billing_period_from}
                                       </FormFeedback>
                                     ) : null}
                                   </div>
                                 </Col>
                                 <Col lg={4}>
                                   <div className="mb-3">
                                     <Label htmlFor="billing_period_to">
                                       Billing Period End Date
                                     </Label>
                                     <Input
                                       name="billing_period_to"
                                       type="date"
                                       className={`form-control ${
                                         validation.touched.billing_period_to &&
                                         validation.errors.billing_period_to
                                           ? "is-invalid"
                                           : ""
                                       }`}
                                       id="billing_period_to"
                                       placeholder="Enter Start Date"
                                       onChange={validation.handleChange}
                                       onBlur={validation.handleBlur}
                                       value={validation.values.billing_period_to || ""}
                                     />
                                     {validation.touched.billing_period_to &&
                                     validation.errors.billing_period_to ? (
                                       <FormFeedback type="invalid">
                                         {validation.errors.billing_period_to}
                                       </FormFeedback>
                                     ) : null}
                                   </div>
                                 </Col>
                                 <Col lg={4}>
                                   <div className="mb-3">
                                     <Label htmlFor="electricity_meter">
                                       Electricity Meter
                                     </Label>
                                     <Input
                                       type="select"
                                       name="electricity_meter"
                                       className="form-control"
                                       id="electricity_meter"
                                       onChange={validation.handleChange}
                                       onBlur={validation.handleBlur}
                                       value={validation.values.electricity_meter || ""}
                                       invalid={
                                         validation.touched.electricity_meter &&
                                         validation.errors.electricity_meter
                                           ? true
                                           : false
                                       }
                                     >
                                       <option value="">Select Electricity Meter</option>
                                       {consumptionMeterList &&
                                         consumptionMeterList.Electricity &&
                                         consumptionMeterList.Electricity.map(
                                           (meterMaster, i) => (
                                             <option key={i} value={meterMaster.id}>
                                               {meterMaster.meter_no}
                                             </option>
                                           )
                                         )}
                                     </Input>
                                     {validation.touched.electricity_meter &&
                                     validation.errors.electricity_meter ? (
                                       <FormFeedback type="invalid">
                                         {validation.errors.electricity_meter}
                                       </FormFeedback>
                                     ) : null}
                                   </div>
                                 </Col>
           
                                 <Col lg={4}>
                                   <div className="mb-3">
                                     <Label htmlFor="dg_meter">DG Meter</Label>
                                     <Input
                                       type="select"
                                       name="dg_meter"
                                       className="form-control"
                                       id="dg_meter"
                                       onChange={validation.handleChange}
                                       onBlur={validation.handleBlur}
                                       value={validation.values.dg_meter || ""}
                                       invalid={
                                         validation.touched.dg_meter &&
                                         validation.errors.dg_meter
                                           ? true
                                           : false
                                       }
                                     >
                                       <option value="">Select DG Meter</option>
                                       {consumptionMeterList &&
                                         consumptionMeterList.DG &&
                                         consumptionMeterList.DG.map((meterMaster, i) => (
                                           <option key={i} value={meterMaster.id}>
                                             {meterMaster.meter_no}
                                           </option>
                                         ))}
                                     </Input>
                                     {validation.touched.dg_meter &&
                                     validation.errors.dg_meter ? (
                                       <FormFeedback type="invalid">
                                         {validation.errors.dg_meter}
                                       </FormFeedback>
                                     ) : null}
                                   </div>
                                 </Col>
           
                                 <Col lg={4}>
                                   <div className="mb-3">
                                     <Label htmlFor="gas_meter">Gas Meter</Label>
                                     <Input
                                       type="select"
                                       name="gas_meter"
                                       className="form-control"
                                       id="gas_meter"
                                       onChange={validation.handleChange}
                                       onBlur={validation.handleBlur}
                                       value={validation.values.gas_meter || ""}
                                       invalid={
                                         validation.touched.gas_meter &&
                                         validation.errors.gas_meter
                                           ? true
                                           : false
                                       }
                                     >
                                       <option value="">Select Gas Meter</option>
                                       {consumptionMeterList &&
                                         consumptionMeterList.Gas &&
                                         consumptionMeterList.Gas.map((meterMaster, i) => (
                                           <option key={i} value={meterMaster.id}>
                                             {meterMaster.meter_no}
                                           </option>
                                         ))}
                                     </Input>
                                     {validation.touched.gas_meter &&
                                     validation.errors.gas_meter ? (
                                       <FormFeedback type="invalid">
                                         {validation.errors.gas_meter}
                                       </FormFeedback>
                                     ) : null}
                                   </div>
                                 </Col>
           
                                 <Col lg={4}>
                                   <div className="mb-3">
                                     <Label htmlFor="water_meter">Water Meter</Label>
                                     <Input
                                       type="select"
                                       name="water_meter"
                                       className="form-control"
                                       id="water_meter"
                                       onChange={validation.handleChange}
                                       onBlur={validation.handleBlur}
                                       value={validation.values.water_meter || ""}
                                       invalid={
                                         validation.touched.water_meter &&
                                         validation.errors.water_meter
                                           ? true
                                           : false
                                       }
                                     >
                                       <option value="">Select Water Meter</option>
                                       {consumptionMeterList &&
                                         consumptionMeterList.Water &&
                                         consumptionMeterList.Water.map(
                                           (meterMaster, i) => (
                                             <option key={i} value={meterMaster.id}>
                                               {meterMaster.meter_no}
                                             </option>
                                           )
                                         )}
                                     </Input>
                                     {validation.touched.water_meter &&
                                     validation.errors.water_meter ? (
                                       <FormFeedback type="invalid">
                                         {validation.errors.water_meter}
                                       </FormFeedback>
                                     ) : null}
                                   </div>
                                 </Col>
                                 {validation.values.billing_period_from &&
                                 validation.values.billing_period_from &&
                                 validation.values.client_project_unit_id ? (
                                   <Col lg={4}>
                                     <Label className="form-label"> </Label>
                                     <div className=" mt-1">
                                       <button
                                         className="btn btn-primary"
                                         onClick={e => {
                                           e.preventDefault()
                                           handleGetConsumption()
                                         }}
                                       >
                                         Get Consumptions
                                       </button>
                                     </div>
                                   </Col>
                                 ) : null}
                               </Row>
                             </CardBody>
                           </Card>
                         </Col>
                       </Row>
            {projectUnitDetail && (
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3">
                        Billing Item Details
                      </CardTitle>
                      <Row>
                        <Col lg={1} style={{ width: "50px" }}>
                          <div className="mb-3">
                            <Label></Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Invoice Components</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Description</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Unit</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Rate</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Amount</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Discount</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>SAC / HSN Code</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>GST Rate</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>GST Amount</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Total</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label> </Label>
                          </div>
                        </Col>
                      </Row>
                      {validation.values.billing_components.map(
                        (row, key) =>
                          row.check === 1 && (
                            <Row key={key}>
                              <Col
                                lg={1}
                                className="d-flex align-items-center justify-content-end"
                                style={{ width: "50px" }}
                              >
                                <div className="mb-4">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`record_${key}`}
                                    name={`billing_components[${key}].check`}
                                    onChange={e => {
                                      const { name, checked } = e.target
                                      validation.setFieldValue(
                                        name,
                                        checked ? 1 : 0
                                      ) // Update form field value
                                    }}
                                    onBlur={validation.handleBlur}
                                    checked={
                                      Number(
                                        validation.values.billing_components[
                                          key
                                        ]?.check
                                      ) === 1
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg={1} className="d-flex align-items-center">
                                <div className="mb-3">
                                  <p
                                    htmlFor={`invoice_type_${key}`}
                                    className="form-label"
                                  >
                                    {
                                      validation.values.billing_components_show[
                                        key
                                      ]?.invoice_component
                                    }
                                  </p>
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`description_${key}`}
                                    name={`billing_components[${key}].description`}
                                    placeholder="Enter Description"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.description || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.description &&
                                    validation.errors.billing_components?.[key]
                                      ?.description && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.description
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`unit${key}`}
                                    name={`billing_components[${key}].unit`}
                                    placeholder="Enter Unit"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.unit || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.unit &&
                                    validation.errors.billing_components?.[key]
                                      ?.unit && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.unit
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`rate_per_unit${key}`}
                                    name={`billing_components[${key}].rate_per_unit`}
                                    placeholder="Enter Rate"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.rate_per_unit || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.rate_per_unit &&
                                    validation.errors.billing_components?.[key]
                                      ?.rate_per_unit && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.rate_per_unit
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`amount_${key}`}
                                    name={`billing_components[${key}].amt_per_unit`}
                                    placeholder="Enter Amount"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.unit *
                                        validation.values.billing_components[
                                          key
                                        ]?.rate_per_unit || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.amt_per_unit &&
                                    validation.errors.billing_components?.[key]
                                      ?.amt_per_unit && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.amt_per_unit
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>

                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`discount_${key}`}
                                    name={`billing_components[${key}].discount`}
                                    placeholder="Enter Discount"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.discount || 0
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.discount &&
                                    validation.errors.billing_components?.[key]
                                      ?.discount && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.discount
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`sac_hsn_code_${key}`}
                                    name={`billing_components_show[${key}].sac_hsn`}
                                    placeholder="NA"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    disabled
                                    value={
                                      validation.values.billing_components_show[
                                        key
                                      ]?.sac_hsn || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.sac_hsn_code &&
                                    validation.errors.billing_components?.[key]
                                      ?.sac_hsn_code && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.sac_hsn_code
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    disabled
                                    id={`gst_percentage${key}`}
                                    name={`billing_components[${key}].gst_percentage`}
                                    placeholder="NA"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.gst_percentage || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.gst_percentage &&
                                    validation.errors.billing_components?.[key]
                                      ?.gst_percentage && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.gst_percentage
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>

                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      (validation.values.billing_components[key]
                                        ?.unit *
                                        validation.values.billing_components[
                                          key
                                        ]?.rate_per_unit -
                                        validation.values.billing_components[
                                          key
                                        ]?.discount) *
                                        (validation.values.billing_components[
                                          key
                                        ]?.gst_percentage /
                                          100) || "0"
                                    }
                                    id={`gst_amount${key}`}
                                    name={`billing_components[${key}].gst_amount`}
                                    placeholder="GST Amount"
                                    disabled
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.gst_amount &&
                                    validation.errors.billing_components?.[key]
                                      ?.gst_amount && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.gst_amount
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    name={`billing_components[${key}].total`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      parseFloat(
                                        validation.values.billing_components[
                                          key
                                        ]?.unit *
                                          validation.values.billing_components[
                                            key
                                          ]?.rate_per_unit || 0
                                      ) -
                                        parseFloat(
                                          validation.values.billing_components[
                                            key
                                          ]?.discount || 0
                                        ) +
                                        (((parseFloat(
                                          validation.values.billing_components[
                                            key
                                          ]?.unit *
                                            validation.values
                                              .billing_components[key]
                                              ?.rate_per_unit
                                        ) || 0) -
                                          parseFloat(
                                            validation.values
                                              .billing_components[key]
                                              ?.discount || 0
                                          )) *
                                          (parseFloat(
                                            validation.values
                                              .billing_components[key]
                                              ?.gst_percentage
                                          ) /
                                            100) || 0) || "0"
                                    }
                                    id={`total_${key}`}
                                    placeholder="Total"
                                    disabled
                                  />
                                </div>
                              </Col>
                              {/* <Col lg={1} className="d-flex align-items-center">
                              <div className="mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`record_${key}`}
                                  name={`billing_components[${key}].check`}
                                  onChange={e => {
                                    const { name, checked } = e.target
                                    validation.setFieldValue(
                                      name,
                                      checked ? 1 : 0
                                    ) // Update form field value
                                  }}
                                  onBlur={validation.handleBlur}
                                  checked={
                                    Number(
                                      validation.values.billing_components[key]
                                        ?.check
                                    ) === 1
                                  }
                                />
                              </div>
                            </Col> */}
                              {/* <Col>
                                <div className="mb-3">
                                  <button
                                    type="button"
                                    // className="btn btn-danger "
                                    style={{
                                      border: "none",
                                      background: "none",
                                      padding: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeRow(row.id)}
                                  >
                                    <i
                                      className="mdi mdi-delete"
                                      style={{
                                        color: "red",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </button>
                                </div>
                              </Col> */}
                            </Row>
                          )
                      )}

                      {validation.values.billing_components.map(
                        (row, key) =>
                          row.check === 0 &&
                          hidden && (
                            <Row key={key}>
                              <Col
                                lg={1}
                                className="d-flex align-items-center justify-content-end"
                                style={{ width: "50px" }}
                              >
                                <div className="mb-4">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`record_${key}`}
                                    name={`billing_components[${key}].check`}
                                    onChange={e => {
                                      const { name, checked } = e.target
                                      validation.setFieldValue(
                                        name,
                                        checked ? 1 : 0
                                      ) // Update form field value
                                    }}
                                    onBlur={validation.handleBlur}
                                    checked={
                                      Number(
                                        validation.values.billing_components[
                                          key
                                        ]?.check
                                      ) === 1
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg={1} className="d-flex align-items-center">
                                <div className="mb-3">
                                  <p
                                    htmlFor={`invoice_type_${key}`}
                                    className="form-label"
                                  >
                                    {
                                      validation.values.billing_components_show[
                                        key
                                      ]?.invoice_component
                                    }
                                  </p>
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`description_${key}`}
                                    name={`billing_components[${key}].description`}
                                    placeholder="Enter Description"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.description || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.description &&
                                    validation.errors.billing_components?.[key]
                                      ?.description && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.description
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`unit${key}`}
                                    name={`billing_components[${key}].unit`}
                                    placeholder="Enter Unit"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.unit || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.unit &&
                                    validation.errors.billing_components?.[key]
                                      ?.unit && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.unit
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`rate_per_unit${key}`}
                                    name={`billing_components[${key}].rate_per_unit`}
                                    placeholder="Enter Rate"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.rate_per_unit || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.rate_per_unit &&
                                    validation.errors.billing_components?.[key]
                                      ?.rate_per_unit && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.rate_per_unit
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`amount_${key}`}
                                    name={`billing_components[${key}].amt_per_unit`}
                                    placeholder="Enter Amount"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.unit *
                                        validation.values.billing_components[
                                          key
                                        ]?.rate_per_unit || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.amt_per_unit &&
                                    validation.errors.billing_components?.[key]
                                      ?.amt_per_unit && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.amt_per_unit
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>

                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`discount_${key}`}
                                    name={`billing_components[${key}].discount`}
                                    placeholder="Enter Discount"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.discount || 0
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.discount &&
                                    validation.errors.billing_components?.[key]
                                      ?.discount && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.discount
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`sac_hsn_code_${key}`}
                                    name={`billing_components_show[${key}].sac_hsn`}
                                    placeholder="NA"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    disabled
                                    value={
                                      validation.values.billing_components_show[
                                        key
                                      ]?.sac_hsn || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.sac_hsn_code &&
                                    validation.errors.billing_components?.[key]
                                      ?.sac_hsn_code && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.sac_hsn_code
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    disabled
                                    id={`gst_percentage${key}`}
                                    name={`billing_components[${key}].gst_percentage`}
                                    placeholder="NA"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.gst_percentage || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.gst_percentage &&
                                    validation.errors.billing_components?.[key]
                                      ?.gst_percentage && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.gst_percentage
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>

                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      (validation.values.billing_components[key]
                                        ?.unit *
                                        validation.values.billing_components[
                                          key
                                        ]?.rate_per_unit -
                                        validation.values.billing_components[
                                          key
                                        ]?.discount) *
                                        (validation.values.billing_components[
                                          key
                                        ]?.gst_percentage /
                                          100) || "0"
                                    }
                                    id={`gst_amount${key}`}
                                    name={`billing_components[${key}].gst_amount`}
                                    placeholder="GST Amount"
                                    disabled
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.gst_amount &&
                                    validation.errors.billing_components?.[key]
                                      ?.gst_amount && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.gst_amount
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    name={`billing_components[${key}].total`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      parseFloat(
                                        validation.values.billing_components[
                                          key
                                        ]?.unit *
                                          validation.values.billing_components[
                                            key
                                          ]?.rate_per_unit || 0
                                      ) -
                                        parseFloat(
                                          validation.values.billing_components[
                                            key
                                          ]?.discount || 0
                                        ) +
                                        (((parseFloat(
                                          validation.values.billing_components[
                                            key
                                          ]?.unit *
                                            validation.values
                                              .billing_components[key]
                                              ?.rate_per_unit
                                        ) || 0) -
                                          parseFloat(
                                            validation.values
                                              .billing_components[key]
                                              ?.discount || 0
                                          )) *
                                          (parseFloat(
                                            validation.values
                                              .billing_components[key]
                                              ?.gst_percentage
                                          ) /
                                            100) || 0) || "0"
                                    }
                                    id={`total_${key}`}
                                    placeholder="Total"
                                    disabled
                                  />
                                </div>
                              </Col>
                              {/* <Col lg={1} className="d-flex align-items-center">
                              <div className="mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`record_${key}`}
                                  name={`billing_components[${key}].check`}
                                  onChange={e => {
                                    const { name, checked } = e.target
                                    validation.setFieldValue(
                                      name,
                                      checked ? 1 : 0
                                    ) // Update form field value
                                  }}
                                  onBlur={validation.handleBlur}
                                  checked={
                                    Number(
                                      validation.values.billing_components[key]
                                        ?.check
                                    ) === 1
                                  }
                                />
                              </div>
                            </Col> */}
                              {/* <Col>
                                <div className="mb-3">
                                  <button
                                    type="button"
                                    // className="btn btn-danger "
                                    style={{
                                      border: "none",
                                      background: "none",
                                      padding: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeRow(row.id)}
                                  >
                                    <i
                                      className="mdi mdi-delete"
                                      style={{
                                        color: "red",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </button>
                                </div>
                              </Col> */}
                            </Row>
                          )
                      )}
                      {hidden ? (
                        <Row>
                          <Col>
                            <div className="mb-3 text-start">
                              <button
                                type="button"
                                className="btn btn-primary w-md me-2"
                                style={{ width: "10%" }}
                                onClick={toggleHidden}
                              >
                                Hide
                              </button>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col>
                            <div className="mb-3 text-start">
                              <button
                                type="button"
                                className="btn btn-primary w-md me-2"
                                style={{ width: "10%" }}
                                onClick={toggleHidden}
                              >
                                Show More
                              </button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Card
                // style={{
                //   backgroundColor: "#EEEEEE",
                //   borderRadius: "15px",
                // }}
                >
                  <CardBody>
                    {/* <CardTitle className="mb-3">
                      Assigned Project Unit Details
                    </CardTitle> */}

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="bill_no">Invoice Number*</Label>
                          <Input
                            type="text"
                            name="bill_no"
                            readOnly
                            className={`form-control ${
                              validation.touched.bill_no &&
                              validation.errors.bill_no
                                ? "is-invalid"
                                : ""
                            }`}
                            id="bill_no"
                            onBlur={validation.handleBlur}
                            value={validation.values.bill_no || ""}
                            invalid={
                              validation.touched.bill_no &&
                              !!validation.errors.bill_no
                            }
                          />
                          {validation.touched.bill_no &&
                            validation.errors.bill_no && (
                              <FormFeedback type="invalid">
                                {validation.errors.bill_no}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="bill_date">Invoice Date</Label>
                          <Input
                            type="date"
                            id="bill_date"
                            name="bill_date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.bill_date}
                            className={`form-control ${
                              validation.touched.bill_date &&
                              validation.errors.bill_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.bill_date &&
                            validation.errors.bill_date && (
                              <FormFeedback type="invalid">
                                {validation.errors.bill_date}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="bill_due_date">Due Date</Label>
                          <Input
                            type="date"
                            id="bill_due_date"
                            name="bill_due_date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.bill_due_date}
                            className={`form-control ${
                              validation.touched.bill_due_date &&
                              validation.errors.bill_due_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.bill_due_date &&
                            validation.errors.bill_due_date && (
                              <FormFeedback type="invalid">
                                {validation.errors.bill_due_date}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="type_of_supply">Type of supply</Label>
                          <select
                            className={`form-select ${
                              validation.touched.type_of_supply &&
                              validation.errors.type_of_supply
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="type_of_supply"
                            value={validation.values.type_of_supply}
                          >
                            <option value="">Select Type of supply</option>
                            {(typeOfSupplyList || []).map(supply => (
                              <option key={supply.id} value={supply.id}>
                                {supply.gst_type_of_supply}
                              </option>
                            ))}
                          </select>
                          {validation.touched.type_of_supply &&
                            validation.errors.type_of_supply && (
                              <div className="invalid-feedback">
                                {validation.errors.type_of_supply}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="place_of_supply">
                            Place of supply
                          </Label>
                          <select
                            className={`form-select ${
                              validation.touched.place_of_supply &&
                              validation.errors.place_of_supply
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="place_of_supply"
                            value={validation.values.place_of_supply}
                          >
                            <option>Select Place of supply</option>
                            {(placeOfSupplyList || []).map(supply => (
                              <option key={supply.id} value={supply.id}>
                                {supply.state}
                              </option>
                            ))}
                          </select>
                          {validation.touched.place_of_supply &&
                            validation.errors.place_of_supply && (
                              <div className="invalid-feedback">
                                {validation.errors.place_of_supply}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="delivery_note">Delivery Note</Label>
                          <Input
                            type="text"
                            name="delivery_note"
                            className={`form-control`}
                            id="delivery_note"
                            placeholder="Enter Delivery Note"
                            onChange={validation.handleChange}
                            value={validation.values.delivery_note || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="delivery_note_date">
                            Delivery Note Date
                          </Label>
                          <Input
                            type="date"
                            name="delivery_note_date"
                            className={`form-control`}
                            id="delivery_note_date"
                            placeholder="Enter Delivery Note Date"
                            onChange={validation.handleChange}
                            value={validation.values.delivery_note_date || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="reference_no">Reference No</Label>
                          <Input
                            type="text"
                            name="reference_no"
                            className={`form-control`}
                            id="reference_no"
                            onChange={validation.handleChange}
                            placeholder="Enter Reference No"
                            value={validation.values.reference_no || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="reference_date">Reference Date</Label>
                          <Input
                            type="date"
                            name="reference_date"
                            className={`form-control`}
                            id="reference_date"
                            placeholder="Enter Reference Date"
                            onChange={validation.handleChange}
                            value={validation.values.reference_date || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="other_references">
                            Other References
                          </Label>
                          <Input
                            type="text"
                            name="other_references"
                            className={`form-control`}
                            id="other_references"
                            placeholder="Enter Other References"
                            onChange={validation.handleChange}
                            value={validation.values.other_references || ""}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="buyers_order_no">
                            Buyers Order No
                          </Label>
                          <Input
                            type="text"
                            name="buyers_order_no"
                            className={`form-control`}
                            id="buyers_order_no"
                            onChange={validation.handleChange}
                            placeholder="Enter Buyers Order No"
                            value={validation.values.buyers_order_no || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="dated">Dated</Label>
                          <Input
                            type="date"
                            name="dated"
                            className={`form-control`}
                            id="dated"
                            placeholder="Enter Dated Date"
                            onChange={validation.handleChange}
                            value={validation.values.dated || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="dispatch_doc_no">
                            Dispatch Doc No
                          </Label>
                          <Input
                            type="text"
                            name="dispatch_doc_no"
                            className={`form-control`}
                            id="dispatch_doc_no"
                            placeholder="Enter Dispatch Doc No"
                            onChange={validation.handleChange}
                            value={validation.values.dispatch_doc_no || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="dispatched_through">
                            Dispatch through
                          </Label>
                          <Input
                            type="text"
                            name="dispatched_through"
                            className={`form-control`}
                            id="dispatched_through"
                            placeholder="Enter Dispatch through"
                            onChange={validation.handleChange}
                            value={validation.values.dispatched_through || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="destination">Destination</Label>
                          <Input
                            type="text"
                            name="destination"
                            className={`form-control`}
                            id="destination"
                            onChange={validation.handleChange}
                            placeholder="Enter Destination"
                            value={validation.values.destination || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="remarks">Remark</Label>
                          <Input
                            type="text"
                            name="remarks"
                            className={`form-control`}
                            id="remarks"
                            onChange={validation.handleChange}
                            placeholder="Enter Remark"
                            value={validation.values.remarks || ""}
                          />
                        </div>
                      </Col>
                     
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button
                    type="button"
                    className="btn btn-primary w-md me-4"
                    style={{ width: "10%" }}
                    onClick={validation.handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditBill)

EditBill.propTypes = {
  history: Proptypes.object,
}
