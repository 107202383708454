import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"

// import { recentfile } from "../../../common/data";
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  deleteAssignedProjectUnit,
  deleteClientUser,
  deleteMeterMaster,
  getAssignedProjectUnitList,
  getClientUserList,
} from "store/actions"
import DeleteModal from "components/Common/DeleteModal"
import Swal from "sweetalert2"
import { getClientConsumptionMeterList } from "helpers/fakebackend_helper"

const AllUnitConsumptionMeter = () => {
  const [clientId, setClientId] = useState()
  const [data, setData] = useState()
  const dispatch = useDispatch()

  const fetchData = async () => {
    const url = new URL(window.location.href)
    const clientId = url.pathname.split("/view-client/")[1]
    setClientId(clientId)
    const response = await getClientConsumptionMeterList(clientId)
    setData(response.data)
  }
  useEffect(() => {
    fetchData()
  }, [])

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    console.log(deleteId)
    if (deleteId && deleteId.id) {
      dispatch(deleteMeterMaster(deleteId.id))
    }
    setDeleteModal(false)
    fetchData()
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="table-responsive">
        <Table className="table align-middle table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th scope="col">Unit Code</th>
              <th scope="col">Meter No</th>
              <th scope="col">Type</th>
              <th scope="col">Is Active</th>
              

              <th scope="col" colSpan={2}>
                Action
              </th>
            </tr>
          </thead>
          {Array.isArray(data) && data?.length > 0 ? (
            <tbody>
              {data?.map((item, key) => (
                <tr key={key}>
                  <td>
                    {item.unit_code}
                  </td>
                  <td>{item.meter_no}</td>
                  <td>{item.type}</td>
                  <td>{String(item.is_active) === "1" ? "Yes" : "No"}</td>
                

                  <td>
                    <div className="d-flex gap-3">
                      {permission
                        .filter(
                          perm =>
                            perm.page === "/manage-consumption/edit-consumption-meter/:id" &&
                            perm.access === 1
                        )
                        .map(index => (
                          <Link
                            key={index}
                            to={`/clients/manage-consumption/edit-consumption-meter/${item.id}`}
                            className="text-success"
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                              style={{ color: "#3366cc" }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="edittooltip"
                            >
                              Edit
                            </UncontrolledTooltip>
                          </Link>
                        ))}
                      {permission
                        .filter(
                          perm =>
                            perm.page === "delete-consumption-meter" &&
                            perm.access === 1
                        )
                        .map(index => (
                          <Link
                            key={index}
                            to="#"
                            className="text-danger"
                            onClick={() => {
                              onClickDelete(item.id)
                            }}
                          >
                            <i
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                              style={{ color: "#3366cc" }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="deletetooltip"
                            >
                              Delete
                            </UncontrolledTooltip>
                          </Link>
                        ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="5" className="text-center">
                  No Records Found
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
      <div className="mt-3 text-end">
        {permission
          .filter(
            perm =>
              perm.page === "/manage-consumption/add-new-consumption-meter/:id" &&
              perm.access === 1
          )
          .map(index => (
            <Link
              key={index}
              to={`/clients/manage-consumption/add-new-consumption-meter/${clientId}`}
            >
              <button type="submit" className="btn btn-primary w-md">
                Add New Consumption Meter
              </button>
            </Link>
          ))}
      </div>

      {/* </div> */}
    </React.Fragment>
  )
}

export default AllUnitConsumptionMeter
