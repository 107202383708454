import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledCollapse,
  Label,
  Input,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap"
import VLogo from "../../assets/images/VIP/VLogo.png"

import { Link, useNavigate } from "react-router-dom"

import classnames from "classnames"
// import { getUserProfile } from "api"
import { useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { reinviteClient } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"
import DeleteModal from "components/Common/DeleteModal"
import { deleteClient, getClients } from "store/actions"
import ClientDetail from "./components/clientDetail"
import ClientDocuments from "./components/clientDocuments"
import AssignedProject from "./components/assignedProjectUnit"
import ClientLedger from "./components/clientLedger"
import AllClientUser from "./ManageUser/allClientUserList"
import AllUnitConsumptionMeter from "./UnitConsumptionMeter/allUnitConsumptionMeter"

const ClientView = () => {
  document.title = "Client Profile"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [modal_center, setmodal_center] = useState(false)
  const [modal_center2, setmodal_center2] = useState(false)
  const [toast2, setToast2] = useState(false)

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [loading, setLoading] = useState(true)
  const [clientId, setClientId] = useState()

  const clientDetail = state => state.clients.clients.client
  const clientProfile = useSelector(clientDetail)

  const fetchData = async () => {
    const url = new URL(window.location.href)
    const clientId = url.pathname.split("/view-client/")[1]
    setClientId(clientId)

    try {
      dispatch(getClients(clientId))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    fetchData()
    setLoading(false)
  }, [dispatch])

  const handleEmail = async () => {
    try {
      const loadingSwal = Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })

      const response = await reinviteClient(clientId)

      // Close loading spinner
      loadingSwal.close()

      if (response && response.status === true) {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Client Has Been Reinvited.",
        })
      } else {
        Swal.fire({
          title: "Failure",
          icon: "error",
          text: "There was a problem while reinviting the Client.",
        })
      }
    } catch (error) {
      console.error("Error:", error)
      Swal.fire({
        title: "Error",
        icon: "error",
        text:
          error.response.data.message ||
          "An error occurred while reinviting the Client.",
      })
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [contact, setContact] = useState()

  const onClickDelete = users => {
    setContact(users)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteClient(clientId))
    setDeleteModal(false)
    navigate("/clients")
    dispatch(getClients(clientId))
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          <CardTitle className="h4" style={{ fontSize: "20px" }}>
            <div className="d-flex gap-2 mb-3">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              Client Profile
            </div>
          </CardTitle>
          <Row>
            <Col lg={12}>
              <Card>
                {clientProfile && clientProfile ? (
                  <CardBody>
                    <Row>
                      <div className="d-flex gap-2 ">
                        <div className="mb-3  ">
                          <img
                            src={clientProfile && clientProfile.image}
                            // src={userProfile ? userProfile.image : avatar2}
                            alt=""
                            style={{ height: "140px", width: "140px" }}
                            className="avatar-xl rounded-4 img-fluid "
                          />
                        </div>
                        <div className="mb-3  " style={{ marginLeft: "10px" }}>
                          <b>{clientProfile && clientProfile.customer_name}</b>
                          <h6 className="mb-4">
                            @{clientProfile && clientProfile.login_username}
                          </h6>

                          <h6 className="text-primary mb-0">
                            {clientProfile && clientProfile.mobile_no}
                          </h6>
                          <h6 className="text-primary mb-2">
                            {clientProfile && clientProfile.email_address}
                          </h6>

                          <Row className="d-flex  ">
                            <Col className="mt-1">
                              {permission
                                .filter(
                                  item =>
                                    item.page === "/edit-client/:id" &&
                                    item.access === 1
                                )
                                .map((item, index) => (
                                  <Link
                                    key={index}
                                    to={`/clients/edit-client/${
                                      clientProfile && clientProfile.id
                                    }`}
                                  >
                                    <button
                                      className="btn btn-soft-light btn-md"
                                      style={{
                                        color: "black",
                                        backgroundColor: "#F6F6F6",
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-pencil"
                                        style={{
                                          color: "#3366cc",
                                          marginRight: "10px",
                                        }}
                                      ></i>{" "}
                                      Edit Profile
                                    </button>
                                  </Link>
                                ))}
                              {permission
                                .filter(
                                  item =>
                                    item.page === "delete-client" &&
                                    item.access === 1
                                )
                                .map((item, index) => (
                                  <button
                                    key={index}
                                    className="btn btn-soft-light btn-md"
                                    onClick={onClickDelete}
                                    style={{
                                      marginLeft: "10px",
                                      color: "black",
                                      fontweight: "bold",
                                      backgroundColor: "#F6F6F6",
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-delete"
                                      style={{
                                        color: "#3366cc",
                                        marginRight: "10px",
                                      }}
                                    ></i>
                                    Delete Client
                                  </button>
                                ))}
                              {permission
                                .filter(
                                  item =>
                                    item.page === "reinvite-client" &&
                                    item.access === 1
                                )
                                .map((item, index) => (
                                  <button
                                    key={index}
                                    className="mdibtn btn-soft-light btn-md"
                                    onClick={handleEmail}
                                    style={{
                                      marginLeft: "10px",
                                      color: "black",
                                      fontweight: "bold",
                                      backgroundColor: "#F6F6F6",
                                    }}
                                  >
                                    <i
                                      className="bx bx-mail-send"
                                      style={{
                                        color: "#3366cc",
                                        marginRight: "10px",
                                      }}
                                    ></i>
                                    Resend Invitation
                                  </button>
                                ))}

                              <div
                                className="position-fixed top-0 end-0 p-3"
                                style={{ zIndex: "1005" }}
                              >
                                <Toast isOpen={toast2} role="alert">
                                  <ToastHeader
                                    toggle={() => setToast2(!toast2)}
                                  >
                                    <img
                                      src={VLogo}
                                      alt=""
                                      className="me-2"
                                      height="18"
                                    />
                                    <strong className="me-auto">
                                      VIP Mall
                                    </strong>
                                  </ToastHeader>
                                  <ToastBody>Reinvited Successfully</ToastBody>
                                </Toast>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Row>

                    <Nav tabs className="nav-tabs-custom ">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Client Details
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        {permission
                          .filter(
                            item =>
                              item.page === "client-documents" &&
                              item.access === 1
                          )
                          .map((item, index) => (
                            <NavLink
                              key={index}
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => {
                                toggleCustom("2")
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-user"></i>
                              </span>
                              <span className="d-none d-sm-block">
                                Documents
                              </span>
                            </NavLink>
                          ))}
                      </NavItem>
                      <NavItem>
                        {permission
                          .filter(
                            item =>
                              item.page === "list-assigned-project-unit" &&
                              item.access === 1
                          )
                          .map((item, index) => (
                            <NavLink
                              key={index}
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "3",
                              })}
                              onClick={() => {
                                toggleCustom("3")
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-envelope"></i>
                              </span>
                              <span className="d-none d-sm-block">
                                Assigned Project Unit
                              </span>
                            </NavLink>
                          ))}
                      </NavItem>
                      <NavItem>
                        {permission
                          .filter(
                            item =>
                              item.page === "/client-ledger" &&
                              item.access === 1
                          )
                          .map((item, index) => (
                            <NavLink
                              key={index}
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "4",
                              })}
                              onClick={() => {
                                toggleCustom("4")
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-envelope"></i>
                              </span>
                              <span className="d-none d-sm-block">
                                Client Ledger
                              </span>
                            </NavLink>
                          ))}
                      </NavItem>
                      <NavItem>
                        {permission
                          .filter(
                            item =>
                              item.page === "/manage-user/all-client-users" &&
                              item.access === 1
                          )
                          .map((item, index) => (
                            <NavLink
                              key={index}
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "5",
                              })}
                              onClick={() => {
                                toggleCustom("5")
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-envelope"></i>
                              </span>
                              <span className="d-none d-sm-block">
                                Manage Users
                              </span>
                            </NavLink>
                          ))}
                      </NavItem>
                      <NavItem>
                        {permission
                          .filter(
                            item =>
                              item.page === "/manage-user/all-client-users" &&
                              item.access === 1
                          )
                          .map((item, index) => (
                            <NavLink
                              key={index}
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "6",
                              })}
                              onClick={() => {
                                toggleCustom("6")
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-envelope"></i>
                              </span>
                              <span className="d-none d-sm-block">
                                Manage Consumption Meter
                              </span>
                            </NavLink>
                          ))}
                      </NavItem>
                    </Nav>

                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <ClientDetail clientProfile={clientProfile} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <ClientDocuments />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <AssignedProject />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <ClientLedger />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col sm="12">
                            <AllClientUser/>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="6">
                        <Row>
                          <Col sm="12">
                            <AllUnitConsumptionMeter/>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                ) : (
                  <Spinners setLoading={setLoading} />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ClientView
