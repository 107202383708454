export const GET_METER_MASTER_LIST = "GET_METER_MASTER_LIST"
export const GET_METER_MASTER_LIST_SUCCESS = "GET_METER_MASTER_LIST_SUCCESS"
export const GET_METER_MASTER_LIST_FAIL = "GET_METER_MASTER_LIST_FAIL"

export const GET_METER_MASTER_DETAIL = "GET_METER_MASTER_DETAIL"
export const GET_METER_MASTER_DETAIL_SUCCESS = "GET_METER_MASTER_DETAIL_SUCCESS"
export const GET_METER_MASTER_DETAIL_FAIL = "GET_METER_MASTER_DETAIL_FAIL"

export const ADD_NEW_METER_MASTER = "ADD_NEW_METER_MASTER"
export const ADD_NEW_METER_MASTER_SUCCESS = "ADD_NEW_METER_MASTER_SUCCESS"
export const ADD_NEW_METER_MASTER_FAIL = "ADD_NEW_METER_MASTER_FAIL"

export const UPDATE_METER_MASTER = "UPDATE_METER_MASTER"
export const UPDATE_METER_MASTER_SUCCESS = "UPDATE_METER_MASTER_SUCCESS"
export const UPDATE_METER_MASTER_FAIL = "UPDATE_METER_MASTER_FAIL"

export const DELETE_METER_MASTER = "DELETE_METER_MASTER"
export const DELETE_METER_MASTER_SUCCESS = "DELETE_METER_MASTER_SUCCESS"
export const DELETE_METER_MASTER_FAIL = "DELETE_METER_MASTER_FAIL"
